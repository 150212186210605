import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
import Seo from "@components/SEO"
import Hero from "@components/blocks/index/Hero"
import About from "@components/blocks/index/About"
import Best from "@components/blocks/index/Best"
import Strong from "@components/blocks/index/Strong"
import Long from "@components/blocks/index/Long"
import Procut from "@components/blocks/index/Product"
import Information from "@components/blocks/index/Information"
import Contact from "@components/blocks/index/Contact"
import Brand from "@components/blocks/index/Brand" 
import { ScrollGide } from "@components/blocks/index/ScrollGide"

import * as css from "@css/components/blocks/Index.module.styl"

export default function Index()
{
	return(
		<>
		<Seo/>
		<div className={css.container}>
			<ScrollGide/>
			<Hero/>
			<About/>
			<Best/>
			<Strong/>
			<Long/>
			<Procut/>
			<Information/>
			<Contact/>
			<Brand/>
		</div>
		</>
	)
}