import React, { PureComponent } from 'react'
import { node, string, object, bool } from 'prop-types'

export const combineHeaders = (searchParams = {}) => {
    return Object.keys(searchParams)
    .map(key => `${key}=${encodeURIComponent(searchParams[key])}`)
    .join('&')
}

export const createContactLink = (tel, sms, facetime, email, headers) => {
    let link
    if (email) {
        link = `mailto:${email}`
    if (headers) {
        link += `?${combineHeaders(headers)}`
    }
    } else if (tel) {
        link = `tel:${tel}`
    } else if (sms) {
        link = `sms:${sms}`
    } else if (facetime) {
        link = `facetime:${facetime}`
    }
    return link
}

class Mailto extends PureComponent {
    render() {
        return this.props.obfuscate ? this.renderMailtodLink() : this.renderLink()
    }

    renderLink() {
        const { tel, sms, facetime, email, obfuscate, headers, children, ...others } = this.props
        return (
            <a href={createContactLink(tel, sms, facetime, email, headers)} {...others}>
                { tel || sms || facetime || email || children}
            </a>
        )
    }

    reverse(s) {
        return s
        .split('')
        .reverse()
        .join('')
    }

    renderMailtodLink() {
        const {
            tel,
            sms,
            facetime,
            email,
            obfuscate,
            headers,
            children,
            obfuscatedHref,
            ...others
        } = this.props
        return (
            <a
                onClick={this.handleClick.bind(this)}
                href={obfuscatedHref}
                {...others}
                style={{unicodeBidi: 'bidi-override' }}
            >
                { !children && this.reverse(tel || sms || facetime || email) }
                { children && children }
            </a>
        )
    }

    handleClick(event) {
        event.preventDefault()
        const { tel, sms, facetime, email, headers } = this.props
        window.location.href = createContactLink(tel, sms, facetime, email, headers)
    }
}

Mailto.propTypes = {
    children: node,
    tel: string,
    sms: string,
    facetime: string,
    email: string,
    headers: object,
    obfuscate: bool,
    obfuscatedHref: string
}

Mailto.defaultProps = {
    obfuscate: true,
    obfuscatedHref: 'http://click-to-open',
}

export default Mailto
