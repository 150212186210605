// extracted by mini-css-extract-plugin
export var animationMarquee = "r_c";
export var b = "r_Y";
export var bestrong = "r_bb";
export var border = "r_Z";
export var container = "r_b";
export var contentA = "r_4";
export var contentB = "r_5";
export var deco = "r_6";
export var imageA = "r_1";
export var imageB = "r_2";
export var imageInner = "r_3";
export var inner = "r_h";
export var label_best = "r_7";
export var label_long = "r_9";
export var label_strong = "r_8";
export var left = "r_bc";
export var paragraph = "r_y";
export var right = "r_bd";
export var text = "r_S";
export var title = "r_k";