import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage, BackgroundFluidImage } from "@components/Image"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import * as css from "@css/components/blocks/Slider.module.styl"
import '@css/components/blocks/Swiper.styl'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

export default function Slider() {
    const q = useStaticQuery(graphql`
        query {
            bg1 : file(relativePath: { eq: "IndexAbout__img__bg-1@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid }}}
            bg2 : file(relativePath: { eq: "IndexAbout__img__bg-2@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid }}}
            bg3 : file(relativePath: { eq: "IndexAbout__img__bg-3@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) { ...GatsbyImageSharpFluid }}}
        }
    `)

    return (
        <div className={css.container}>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                centeredSlides
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                effect={'fade'}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                }}
                speed={600}
                loop
                loopedSlides={1}
            >
                <SwiperSlide>
                    <BackgroundFluidImage data={q.bg1}>
                        <div className={css.item}></div>
                    </BackgroundFluidImage>
                </SwiperSlide>
                <SwiperSlide>
                    <BackgroundFluidImage data={q.bg2}>
                        <div className={css.item}></div>
                    </BackgroundFluidImage>
                </SwiperSlide>
                <SwiperSlide>
                    <BackgroundFluidImage data={q.bg3}>
                        <div className={css.item}></div>
                    </BackgroundFluidImage>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}
