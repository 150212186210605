import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
import { useGetLink } from "@hooks/useURL"
import { FluidImage, BackgroundFluidImage } from "@components/Image"
// import { img } from "@components/img"
import * as Animations from "@components/Animations"
import * as css from "@css/components/blocks/index/Best.module.styl"
// import { ref } from "yup"

export default function Best()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexBest__img__bg@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }}}
            b : file(relativePath: { eq: "Common__img__b.svg" }) { publicURL }
            title : file(relativePath: { eq: "IndexBest__lbl__best.svg" }) { publicURL }
            bestrong : file(relativePath: { eq: "Common__lbl__be-strong.svg" }) { publicURL }
            border : file(relativePath: { eq: "Common__img__border.svg" }) { publicURL }
        }
    `)
    const containerRef = React.useRef()
    return(
        <section className={css.container} ref={containerRef}>
            <div className={css.inner}>
                <figure className={css.imageA}>
                    <Animations.PraraxImage container={containerRef} className={css.imageInner}>
                        <FluidImage data={q.bg} />
                        {/* <img src={q.bg.childImageSharp.fluid.src}/> */}
                    </Animations.PraraxImage>
                </figure>
                <div className={css.contentA}>
                    <div className={css.right}>
                        <div className={css.deco}>
                            <Animations.FadeIn className={css.label_best}>
                                <img src={q.title.publicURL} alt='BEST' />
                            </Animations.FadeIn>
                            <div className={css.border}>
                                <img src={q.border.publicURL}/>
                            </div>
                            <Animations.FadeIn className={css.bestrong}>
                                <img src={q.bestrong.publicURL} alt='BE-STRONG'/>
                            </Animations.FadeIn>
                        </div>
                    </div>
                    <div className={css.left}>
                        <Animations.FadeIn>
                            <div className={css.paragraph}>
                                <Animations.FadeIn2 className={css.b} delay={0.6}>
                                    <img src={q.b.publicURL} />
                                </Animations.FadeIn2>
                                <h2 className={css.title}>
                                    あげられた情報をベストロングがリサーチし丁寧に洗い出します。ニーズを探り最善の戦略を提案します。
                                </h2>
                                <p className={css.text}>
                                    調べた情報からプロダクトのデザイン性、柔軟性、コストなど、お客様が求める商品と、優先順位を明確化し、見えざるプロダクトを丁寧にカタチにしていきます。一方が優れていてもうまく事が運びません、プロダクトの必要性とバランスを大切に考えます。
                                </p>
                            </div>
                        </Animations.FadeIn>
                    </div>
                </div>
            </div>
        </section>
    )
}
