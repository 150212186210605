import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Svg } from "@components/Svg"
// import { FluidImage, BackgroundFluidImage } from "@components/Image"
import * as css from "@css/components/blocks/index/ScrollGide.module.styl"

function ScrollGide()
{
    const q = useStaticQuery(graphql`
        query {
            scrollText : file(relativePath: { eq: "Common__lbl__scroll.svg" }) { publicURL }
            scrollB : file(relativePath: { eq: "Common__img__scroll-b.svg" }) { publicURL }
        }
    `)
    return(
        <div className={css.container}>
            <div className={css.text}>
                <img src={q.scrollText.publicURL} />
            </div>
            <div className={css.border}></div>
            <div className={css.b}>
                <img src={q.scrollB.publicURL} />
            </div>
        </div>
    )
}

export { ScrollGide }