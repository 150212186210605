// extracted by mini-css-extract-plugin
export var ani = "t_bk";
export var animationMarquee = "t_c";
export var b = "t_Y";
export var bg = "t_bj";
export var bgWrapper = "t_bh";
export var button = "t_p";
export var container = "t_b";
export var content = "t_H";
export var inner = "t_h";
export var links = "t_n";
export var title = "t_k";