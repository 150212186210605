import React, { useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
import { useGetLink } from "@hooks/useURL"
import { FluidImage, BackgroundFluidImage } from "@components/Image"
import { gsap, Power4 } from "gsap"
import { Svg } from "@components/Svg"
// import { ScrollGide } from "@components/blocks/index/ScrollGide"
import Slider from "@components/blocks/Slider"
import * as css from "@css/components/blocks/index/About.module.styl"

export default function About()
{
    const q = useStaticQuery(graphql`
        query {
            bg1 : file(relativePath: { eq: "IndexAbout__img__bg-1@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1280) { ...GatsbyImageSharpFluid }}}
            bg2 : file(relativePath: { eq: "IndexAbout__img__bg-2@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1280) { ...GatsbyImageSharpFluid }}}
            bg3 : file(relativePath: { eq: "IndexAbout__img__bg-3@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1280) { ...GatsbyImageSharpFluid }}}
            bgCover : file(relativePath: { eq: "IndexAbout__img__bg-cover.svg" }) { publicURL }
            scrollText : file(relativePath: { eq: "Common__lbl__scroll.svg" }) { publicURL }
            scrollB : file(relativePath: { eq: "Common__img__scroll-b.svg" }) { publicURL }
        }
    `)

    return(
        <section className={css.container}>
            <div className={css.bgSlide}>
                <Slider />
            </div>
            <div className={css.cover}>
                <img src={q.bgCover.publicURL} />
            </div>
            <div className={css.wrapper}>
                <div className={css.inner}>
                    {/* <ScrollGide/> */}
                    <div className={css.content}>
                        <div className={css.paragraph}>
                            <h2 className={css.title}>
                                ベストロングがお客様の課題を俯瞰で捉え、お客様と共に考えます。成長、成功への鍵となる道筋へ導きます。
                        </h2>
                            <p className={css.text}>
                                お客様が想い描いているプロダクトをカタチにする。それが私たちのビジネスフレームです。これまでベストロングが培った多角的な販売実績と海外の仕入国から得た情報は、経験したからこそわかること、価値があることです。お客様が叶えたいカタチを最短で、最良へ導きます。
                        </p>
                            <p className={css.buttonWrapper}>
                                <Link className={css.button} to={'/company/'}>会社概要</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
