// extracted by mini-css-extract-plugin
export var animationMarquee = "m_c";
export var bestrongJp = "m_T";
export var container = "m_b";
export var content = "m_H";
export var inner = "m_h";
export var longlasting = "m_R";
export var paragraph = "m_y";
export var symbol = "m_N";
export var t1 = "m_P";
export var t2 = "m_Q";
export var text = "m_S";
export var title = "m_k";