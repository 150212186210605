import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
// import { useGetLink } from "@hooks/useURL"
import { FluidImage, BackgroundFluidImage } from "@components/Image"
import * as Animations from "@components/Animations"
// import { img } from "@components/img"
import * as css from "@css/components/blocks/index/Brand.module.styl"

export default function Brand()
{
    const q = useStaticQuery(graphql`
        query {
            longLasting : file(relativePath: { eq: "IndexFooter__lbl__long-lasting.svg" }) { publicURL }
            bestStrongLong : file(relativePath: { eq: "IndexFooter__lbl__best-strong-long.svg" }) { publicURL }
            bg : file(relativePath: { eq: "IndexFooter__img__bg@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }}}
        }
    `)

    return(
        <BackgroundFluidImage data={q.bg} className={css.wrapper}>
            <section className={css.container}>
                <div className={css.inner}>
                    <div className={css.content}>
                        <Animations.FadeIn3>
                            <h2 className={css.label}>
                                <img src={q.longLasting.publicURL} alt='long lasting' />
                            </h2>
                        </Animations.FadeIn3>
                        <Animations.FadeIn3>
                            <figure className={css.title}>
                                <img src={q.bestStrongLong.publicURL} alt='BEST × STRONG × LONG'/>
                            </figure>
                        </Animations.FadeIn3>
                        <Animations.FadeIn3>
                            <Link className={css.goToTop} to="/" scroll="#root-container">TOP</Link>
                        </Animations.FadeIn3>
                    </div>
                </div>
            </section>
        </BackgroundFluidImage>
    )
}
