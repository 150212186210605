// extracted by mini-css-extract-plugin
export var animationMarquee = "n_c";
export var b = "n_Y";
export var bgSlide = "n_V";
export var border = "n_Z";
export var button = "n_p";
export var buttonWrapper = "n_0";
export var container = "n_b";
export var content = "n_H";
export var cover = "n_W";
export var inner = "n_h";
export var paragraph = "n_y";
export var scroll = "n_X";
export var text = "n_S";
export var title = "n_k";