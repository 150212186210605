// extracted by mini-css-extract-plugin
export var animationMarquee = "q_c";
export var b = "q_Y";
export var bestrong = "q_bb";
export var border = "q_Z";
export var container = "q_b";
export var contentA = "q_4";
export var contentB = "q_5";
export var deco = "q_6";
export var imageA = "q_1";
export var imageB = "q_2";
export var imageInner = "q_3";
export var inner = "q_h";
export var label_best = "q_7";
export var label_long = "q_9";
export var label_strong = "q_8";
export var left = "q_bc";
export var paragraph = "q_y";
export var right = "q_bd";
export var text = "q_S";
export var title = "q_k";