import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
import NewsList from "@components/blocks/NewsList"
import { useGetLink } from "@hooks/useURL"
// import { FluidImage, BackgroundFluidImage } from "@components/Image"
import * as Animations from "@components/Animations"
import { Svg } from "@components/Svg"
import * as css from "@css/components/blocks/index/Information.module.styl"

export default function Information()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexInfo__img__bg.svg" }) { publicURL }
            title : file(relativePath: { eq: "IndexInfo__lbl__information.svg" }) { publicURL }
            b : file(relativePath: { eq: "Common__img__b-white.svg" }) { publicURL }

            newsData: allWpPost(
                limit: 5
                sort: { order: DESC, fields: date }
            ) {
				edges {
					node {
                        id
                        title
                        date(formatString: "YYYY.MM.DD")
                        databaseId
                        uri
					}
				}
			}
        }
    `)
    const containerRef = React.useRef()
    return(
        <section className={css.container} ref={containerRef}>
            <div className={css.inner}>
                <Animations.PraraxSlide container={containerRef} className={css.bgWrapper} x={-300}>
                    <figure className={css.bg} style={{ backgroundImage: `url(${q.bg.publicURL})` }} />
                </Animations.PraraxSlide>
                <div className={css.content}>
                    <h2 className={css.title}>
                        <Animations.FadeIn>
                            <img src={q.title.publicURL} alt='INFORMATION' />
                        </Animations.FadeIn>
                    </h2>
                    {/* <div className={css.list}>
                        { data.map(( {date, title},i)=>{
                            return(
                                <div className={css.item} key={i}>
                                    <span className={css.date}>{date}</span>
                                    <span className={css.title}>{title}</span>
                                </div>
                            )
                        })}
                    </div> */}
                    <NewsList data={q.newsData.edges} moreLink="/information/"/>
                    <div className={css.links}>
                        <Link className={css.button} to="https://www.facebook.com/Be-strong-109082494354700/?modal=admin_todo_tour&notif_id=1605584258965944&notif_t=page_invite&ref=notif" blank>Facebook</Link>
                    </div>
                    <Animations.FadeIn2>
                        <figure className={css.b}>
                            <img src={q.b.publicURL} />
                        </figure>
                    </Animations.FadeIn2>
                </div>
            </div>
        </section>
    )
}
