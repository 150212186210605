// extracted by mini-css-extract-plugin
export var animationMarquee = "v_c";
export var b = "v_Y";
export var badge = "v_bm";
export var button = "v_p";
export var container = "v_b";
export var content = "v_H";
export var inner = "v_h";
export var links = "v_n";
export var mail = "v_bl";
export var text = "v_S";
export var title = "v_k";