import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
import { useGetLink } from "@hooks/useURL"
import { FluidImage, BackgroundFluidImage } from "@components/Image"
import * as Animations from "@components/Animations"
import { Svg } from "@components/Svg"
import * as css from "@css/components/blocks/index/Product.module.styl"

export default function Long()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexProduct__img__bg@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }}}
            title : file(relativePath: { eq: "IndexProduct__lbl__original-product.svg" }) { publicURL }
            slash : file(relativePath: { eq: "IndexProduct__img__slash.svg" }) { publicURL }
            bestrong : file(relativePath: { eq: "Common__lbl__be-strong-white.svg" }) { publicURL }
        }
    `)
    const containerRef = React.useRef()
    return(
        <section className={css.container} ref={containerRef}>
            <div className={css.inner}>
                <div className={css.label}>
                    <Animations.FadeIn>
                        <img src={q.bestrong.publicURL} alt='BE-STRONG' />
                    </Animations.FadeIn>
                </div>
                
                <h2 className={css.title}>
                    <Animations.FadeIn>
                        <img src={q.title.publicURL} alt='ORIGINAL PRODICT' />
                    </Animations.FadeIn>
                </h2>
                <figure className={css.image}>
                    <FluidImage data={q.bg} />
                    <div className={css.slash}>
                        <Animations.PraraxSlide container={containerRef}>
                            <img src={q.slash.publicURL} />
                        </Animations.PraraxSlide>
                    </div>
                </figure>
                <Animations.FadeIn className={css.paragraph}>
                        <h3 className={css.title}>
                            ベストロングが開発したコロナ時代を見据えたオリジナルプロダクト商品です。
                        </h3>
                        <p className={css.text}>
                            快適フィットマスク：40枚入
                        </p>
                </Animations.FadeIn>
            </div>
        </section>
    )
}
