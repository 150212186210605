import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
import { useGetLink } from "@hooks/useURL"
import { FluidImage, BackgroundFluidImage } from "@components/Image"
import * as Animations from "@components/Animations"
import { Svg } from "@components/Svg"
import * as css from "@css/components/blocks/index/Strong.module.styl"

export default function Strong()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexStrong__img__bg@2x.jpg" }) { childImageSharp { fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }}}
            b : file(relativePath: { eq: "Common__img__b.svg" }) { publicURL }
            title : file(relativePath: { eq: "IndexStrong__lbl__strong.svg" }) { publicURL }
            bestrong : file(relativePath: { eq: "Common__lbl__be-strong.svg" }) { publicURL }
            border : file(relativePath: { eq: "Common__img__border.svg" }) { publicURL }
        }
    `)
    const containerRef = React.useRef()
    return(
        <section className={css.container} ref={containerRef}>
            <div className={css.inner}>
                <figure className={css.imageA}>
                    <Animations.PraraxImage container={containerRef} className={css.imageInner}>
                        <FluidImage data={q.bg} />
                        {/* <img src={q.bg.childImageSharp.fluid.src} /> */}
                    </Animations.PraraxImage>
                </figure>
                <div className={css.contentB}>
                    <div className={css.left}>
                        <div className={css.deco}>
                            <Animations.FadeIn className={css.label_best}>
                                <img src={q.title.publicURL} alt='BEST' />
                            </Animations.FadeIn>
                            <div className={css.border}>
                                <img src={q.border.publicURL} />
                            </div>
                            <Animations.FadeIn className={css.bestrong}>
                                <img src={q.bestrong.publicURL} alt='BE-STRONG' />
                            </Animations.FadeIn>
                        </div>
                    </div>
                    <div className={css.right}>
                        <Animations.FadeIn>
                            <div className={css.paragraph}>
                                <Animations.FadeIn2 className={css.b}>
                                    <img src={q.b.publicURL} />
                                </Animations.FadeIn2>
                                <h2 className={css.title}>
                                    課題を洗い出し、リスクポイントを回避しアイデアを掛け算する。ベストロングがお客様の出来ることの最大化をお手伝いをします。
                                </h2>
                                <p className={css.text}>
                                    プロダクトの製品化をサポートすると共に、製品特性を考慮し販路もアドバイス、サポートします。商品はカタチになってからが大切です。最高なプロダクトであっても、誰の手に届くか、どう使われるか、ユーザーの顔をリアルにすることでビジョンが明確になります。
                                </p>
                            </div>
                        </Animations.FadeIn>
                    </div>
                </div>
            </div>
        </section>
    )
}
