// extracted by mini-css-extract-plugin
export var animationMarquee = "p_c";
export var b = "p_Y";
export var bestrong = "p_bb";
export var border = "p_Z";
export var container = "p_b";
export var contentA = "p_4";
export var contentB = "p_5";
export var deco = "p_6";
export var imageA = "p_1";
export var imageB = "p_2";
export var imageInner = "p_3";
export var inner = "p_h";
export var label_best = "p_7";
export var label_long = "p_9";
export var label_strong = "p_8";
export var left = "p_bc";
export var paragraph = "p_y";
export var right = "p_bd";
export var text = "p_S";
export var title = "p_k";