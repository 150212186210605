import React from "react"
import Mailto from '@components/Mailto'

export default function ContactButton({css})
{
    return(
        <Mailto
            className={css.button}
            obfuscate={true}
            email='hogehoge-info@be-strong.com'
            headers={
                {
                    cc: '',
                    bcc: '',
                    subject: '【BE-STRONG WEBSITE】お問い合わせ',
                    body: `【会社名/団体名】*

【ご担当者名】*

【電話番号/FAX】*

【お問合せ内容】*
                    `
                }
            }>
            お問い合わせ
        </Mailto>
    )
}
