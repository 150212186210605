import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
import ContactButton from "@components/blocks/ContactButton"
// import { FluidImage, BackgroundFluidImage } from "@components/Image"
import { Svg } from "@components/Svg"
import * as Animations from "@components/Animations"
import * as css from "@css/components/blocks/index/Contact.module.styl"
import BadgeSvg from "@images/IndexContact__img__mail-badge.svg"

export default function Contact()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexContact__lbl__contact.svg" }) { publicURL }
            mail : file(relativePath: { eq: "IndexContact__img__mail.svg" }) { publicURL }
            b : file(relativePath: { eq: "Common__img__b-gray.svg" }) { publicURL }
        }
    `)

    return(
        <section className={css.container} id="contact">
            <div className={css.inner}>
                <div className={css.content}>
                    <h2 className={css.title}>
                        <Animations.FadeIn>
                            <img src={q.title.publicURL} alt='CONTACT' />
                        </Animations.FadeIn>
                    </h2>
                    <figure className={css.mail}>
                        <Animations.ScaleIn className={css.badge}>
                            <BadgeSvg/>
                        </Animations.ScaleIn>
                        <img src={q.mail.publicURL} />
                    </figure>
                    <div className={css.links}>
                        <ContactButton css={css}/>
                    </div>
                    <p className={css.text}>
                        お問い合わせ、ご意見、ご質問はメールにて承っております。
                    </p>
                    <Animations.FadeIn2>
                        <figure className={css.b}>
                            <img src={q.b.publicURL} />
                        </figure>
                    </Animations.FadeIn2>
                </div>
            </div>
        </section>
    )
}
