// extracted by mini-css-extract-plugin
export var animationMarquee = "w_c";
export var b = "w_Y";
export var button = "w_p";
export var container = "w_b";
export var content = "w_H";
export var goToTop = "w_bn";
export var inner = "w_h";
export var label = "w_j";
export var links = "w_n";
export var title = "w_k";