import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

// import { FluidImage, BackgroundFluidImage } from "@components/Image"
import { Svg } from "@components/Svg"
import { TransitionContext } from '@layouts/TransitionLayout'
import { gsap, Power4 } from "gsap"

import * as css from "@css/components/blocks/index/Hero.module.styl"

export default function Hero()
{
    const q = useStaticQuery(graphql`
        query {
            bestStrongLong : file(relativePath: { eq: "IndexHero__lbl__best-strong-long.svg" }) { publicURL }
            bestrong : file(relativePath: { eq: "IndexHero__lbl__be-strong.svg" }) { publicURL }
            longLasting : file(relativePath: { eq: "IndexHero__lbl__long-lasting.svg" }) { publicURL }
            bestrongJp : file(relativePath: { eq: "IndexHero__lbl__bestrong-jp.svg" }) { publicURL }
            b : file(relativePath: { eq: "IndexHero__img__b.svg" }) { publicURL }
        }
    `)

    const containerRef = React.useRef()
    const title1Ref = React.useRef()
    const title2Ref = React.useRef()
    const pLonglastingRef = React.useRef()
    const pTitleRef = React.useRef()
    const pTextRef = React.useRef()
    const pBestrongRef = React.useRef()
    const symbolRef = React.useRef()

    const stay=()=>{
        gsap.to(title1Ref.current,
            {
                opacity: 0,
                x: 100,
        })
        gsap.to(title2Ref.current,
            {
                opacity: 0,
                x: 100,
        })
        gsap.to(pLonglastingRef.current,
            {
                opacity: 0,
                y: 50,
            })
        gsap.to(pTitleRef.current,
            {
                opacity: 0,
                y: 50,
            })
        gsap.to(pTextRef.current,
            {
                opacity: 0,
                y: 50,
            })
        gsap.to(pBestrongRef.current,
            {
                opacity: 0,
                y: 50,
            })
        gsap.to(symbolRef.current,
            {
                opacity: 0,
                y: -100,
            })
    }

    const startAnimation = ()=>{
        let spd = 1.2
        let delay = 0
        let ease = Power4.easeOut
        gsap.fromTo(title1Ref.current,
            {
                opacity: 0,
                x: 100,
            },{
                ease : ease,
                delay: delay,
                duration: spd,
                opacity: 1,
                x: 0,
            })
        gsap.fromTo(title2Ref.current,
            {
                opacity: 0,
                x: 100,
            }, {
                ease: ease,
                delay: delay + 0.1,
                duration: spd,
                opacity: 1,
                x: 0,
        })
        gsap.fromTo(pLonglastingRef.current,
            {
                opacity: 0,
                y: 50,
            }, {
                ease: ease,
                delay: delay + 0.6,
                duration: spd*1.5,
                opacity: 1,
                y: 0,
        })
        gsap.fromTo(pTitleRef.current,
            {
                opacity: 0,
                y: 50,
            }, {
                ease: ease,
                delay: delay + 0.7,
                duration: spd*1.5,
                opacity: 1,
                y: 0,
        })
        gsap.fromTo(pTextRef.current,
            {
                opacity: 0,
                y: 50,
            }, {
            ease: ease,
            delay: delay + 0.8,
            duration: spd * 1.5,
            opacity: 1,
            y: 0,
        })
        gsap.fromTo(pBestrongRef.current,
            {
                opacity: 0,
                y: 50,
            }, {
            ease: ease,
            delay: delay + 0.9,
            duration: spd * 1.5,
            opacity: 1,
            y: 0,
        })
        gsap.fromTo(symbolRef.current,
            {
                opacity: 0,
                y: -100,
            }, {
            ease: ease,
            delay: delay + 0.3,
            duration: spd * 1.5,
            opacity: 1,
            y: 0,
        })
    }
    useEffect(()=>{
        stay()
    },[])
    return(
        <div className={css.container} ref={containerRef}>
            <TransitionContext.Consumer>
                {({ transitionState, setTranstionState }) => {
                    if(transitionState === 0){
                        startAnimation()
                    }
                }}
            </TransitionContext.Consumer>
            <div className={css.inner}>
                <div className={css.symbol}>
                    <div ref={symbolRef}>
                        <img src={q.b.publicURL} />
                    </div>
                </div>
                <div className={css.content}>
                    <h1 className={css.title}>
                        <span className={css.t1} ref={title1Ref}>
                            <img src={q.bestStrongLong.publicURL} alt="BEST × STRONG × LONG"/>
                        </span>
                        <span className={css.t2} ref={title2Ref}>
                            <img src={q.bestrong.publicURL} alt="BE-STRONG" />
                        </span>
                    </h1>
                    <div className={css.paragraph}>
                        <div className={css.longlasting} ref={pLonglastingRef}>
                            <img src={q.longLasting.publicURL} alt="long lasting" />
                        </div>
                        <h2 className={css.title} ref={pTitleRef}>
                            働くをサポートする<br/>ベストロング
                        </h2>
                        <p className={css.text} ref={pTextRef}>
                            はじめましてべストロングです。３つのキーワードが掛け合わせることで生まれる新しい造語ベストロング。キーワードを紐解くとベスト、ストロング、ロングこの３つの言葉がバックグラウンドにあります。環境、働き方、サステイナブルな社会に思考をフォーカスし今の時代に真摯に向き合い、プロダクトを生み出す企業、それが私たちべストロングです。
                        </p>
                        <div className={css.bestrongJp} ref={pBestrongRef}>
                            <img src={q.bestrongJp.publicURL} alt="long lasting" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
